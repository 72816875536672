import React from "react";
import logo from "../images/GL.png";

function Header() {
  return (
    <header className="bg-blue-600 mx-auto md:mx-0 md:px-6">
      {/*<div className="my-8">*/}
      {/*  <img className="w-20 " src={logo} alt="logo" />*/}
      {/*</div>*/}
    </header>
  );
}

export default Header;
