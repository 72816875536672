import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faCalendarCheck,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
// import { faRProject } from "@fortawesome/free-brands-svg-icons";
import Layout from "../components/layout";
import SEO from "../components/seo";
import heroImg from "../images/hero1.svg";
import investabit from "../images/investabit.png";
import agi from "../images/agi.png";
import { navigate } from "gatsby-link";
import Header from "../components/header";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

function IndexPage() {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };
  return (
    <Layout>
      <SEO keywords={[`Ascend Global`]} title="Home" />

      <section className="max-w-5xl md:flex mb-4 px-4 py-8 mx-auto md:px-8 md:py-16">
        <div className="">
          <div className="text-white md:my-auto">
            <Header />
            <h2 className="text-center text-5xl font-bold">Site Under Construction</h2>
            <p className="text-center leading-loose">
              We apologize for any inconvenience as our site is under construction. Please fill out the form below to get in contact with us.
            </p>
          </div>
        </div>
      </section>
      <section className="mb-4 px-4 mx-auto md:px-8">
        <h2 className="text-5xl font-bold text-white text-center">
          {/*Let&apos;s Work*/}
        </h2>
        <div className="">
          <p className="mb-8 text-center text-white">
            {/*Feel free to reach out and let&apos;s collab on something new.*/}
          </p>
          {/* FORM */}
          <form
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            className="w-full"
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="contact" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <div className="mx-auto max-w-2xl pb-4">
              <div className="text-white">
                <label className="block uppercase tracking-wide text-xs font-bold mb-2">
                  Full Name
                  <input
                    className="appearance-none block w-full bg-white border-gray-800 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-200 text-gray-800"
                    name="name"
                    id="form-full-name"
                    type="text"
                    placeholder="Jane"
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div className="text-white">
                <label className="block uppercase tracking-wide text-xs font-bold mb-2">
                  Phone
                  <input
                    className="appearance-none block w-full bg-white border-gray-800 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-200 text-gray-800"
                    id="form-phone"
                    type="tel"
                    name="phone"
                    placeholder="555-555-5555"
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div className="text-white">
                <label className="block uppercase tracking-wide text-xs font-bold mb-2">
                  Email
                  <input
                    className="appearance-none block w-full bg-white border-gray-800 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-200 text-gray-800"
                    id="form-email"
                    type="email"
                    placeholder="jon@gmail.com"
                    name="email"
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div className="text-white">
                <label className="block uppercase tracking-wide text-xs font-bold mb-2">
                  Message
                  <textarea
                    className="appearance-none block w-full bg-white border-gray-800 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-200 text-gray-800"
                    name="textarea"
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Please give some details about the project..."
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
            </div>
            <div className="mx-auto max-w-2xl">
              <button
                className="bg-transparent hover:text-gray-300 hover:border-gray-300 text-white font-semibold py-2 px-4 border border-white rounded"
                type="submit"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
